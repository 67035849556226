import { createSlice } from '@/shared/utils'

import { TUser } from './user.types'

export const userStore = createSlice<TUser>({
	id: 0,
	avatar: null,
	tg_uid: '',
	username: '',
	tg_username: '',
	balance: 0,
	games_total: 0,
	looses: 0,
	wins: 0
})
