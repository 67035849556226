import { WebSocketManager } from '@/shared/utils'

import { gameDynamicsInit } from './game.init'
import { gameStore } from './game.store'
import type { Animations, AutoRebuy, GameWS, TablePosition } from './game.types'

export const gameCreateWS = (gameId: number = 1) => {
	gameStore.setState(s => {
		s.ws = new WebSocketManager(`/game/${gameId}/`)
		s.ws.connect()
	})
}

export const gameRemoveWS = () => {
	gameStore.setState(s => {
		if (s.ws) {
			s.ws.close()
			s.ws = null
			s.playerSeat = null
			s.autoRebuy = { active: false, ammount: 0 }
			s.game = { ...gameDynamicsInit }
		} else console.error(`Game websocket in null`)
	})
}

export const gameChangeState = ({ type, ...data }: GameWS['game_state']) => {
	void type
	gameStore.setState(s => {
		s.game = { ...s.game, ...data }
	})
}

export const gameSetPlayerSeat = (index: number) => {
	gameStore.setState(s => {
		s.playerSeat = index
	})
}

export const gameSetPositions = (positions: TablePosition[]) => {
	console.log('positions change', positions)
	gameStore.setState(s => {
		s.positions = [...positions]
	})
}

export const gameSetAutoRebuy = (data: AutoRebuy) => {
	gameStore.setState(s => {
		s.autoRebuy = { ...data }
	})
}

export const gameSetPremove = (premove: GameWS['action'] | null) => {
	gameStore.setState(s => {
		s.premove = premove ? { ...premove } : null
	})
}

export const gameSetAnimation = (animations: Partial<Animations>) => {
	gameStore.setState(s => {
		s.animations = { ...s.animations, ...animations }
	})
}

export const gameSetActivePlayers = (arr: number[]) => {
	gameStore.setState(s => {
		s.activePlayers = new Set(arr)
	})
}
