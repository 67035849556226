import { createStore } from 'zustand'
import { createJSONStorage, persist } from 'zustand/middleware'

import { FilesStore } from './files.types'

export const filesStore = createStore(
	persist<FilesStore>(() => ({ cards: [] }), {
		name: 'cards',
		storage: createJSONStorage(() => sessionStorage)
	})
)
