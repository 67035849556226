import { useEffect } from 'react'
import { useStore } from 'zustand'

import {
	overlayCreateInstance,
	overlayRemoveInstance,
	overlayToggleVisibility
} from '../model/overlay.actions'
import { overlayStore } from '../model/overlay.store'
import { OverlayID } from '../model/overlay.types'

export function useOverlay(id: OverlayID, isDefaultVisible = false) {
	const overlays = useStore(overlayStore, s => s.overlays)
	const isOverlayVisible = overlays.get(id) ?? false

	useEffect(() => {
		if (overlays.has(id)) return

		overlayCreateInstance(id, isDefaultVisible)

		return () => {
			if (overlayStore.getState().overlays.has(id)) {
				overlayRemoveInstance(id)
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id])

	const onToggleOverlay = (isVisible?: boolean) => {
		overlayToggleVisibility(id, isVisible)
	}

	return [isOverlayVisible, onToggleOverlay] as const
}
