import { memo } from 'react'

import { Overlay, useOverlay } from '@/components/Overlay'

import { UserData } from '../UserData/UserData'
import { UserMiniProfile } from '../UserMiniProfile/UserMiniProfile'

import c from './UserInHeader.module.scss'

export const UserInHeader = memo(function UserInHeader() {
	const [isUserOverlayVisible, setUserOverlayVisible] = useOverlay('user-mini-profile')
	const onShowUserOverlay = () => setUserOverlayVisible(true)

	return (
		<div className={c['user-in-header']} onClick={onShowUserOverlay}>
			<UserData direction='horizontal' />
			{isUserOverlayVisible && <Overlay children={<UserMiniProfile />} transparent smooth />}
		</div>
	)
})
