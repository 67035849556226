// import { chairs } from './game.mock'
import type { DynamicGameData } from './game.types'

export const gameDynamicsInit: DynamicGameData = {
	current_stage: null,
	round_active: false,
	table: [],
	// chairs: chairs,
	chairs: [],
	current_dealer: 0,
	current_turn: { end_at: null, user_id: null },

	pot: 0,
	current_bet: 0,
	last_bet: 0,
	available_actions: [],

	blind_amount: 0,
	small_blind_position: 0,
	big_blind_position: 0,
	user_balance: 0
}
