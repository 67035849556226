import { useTranslation } from 'react-i18next'
import { useStore } from 'zustand'

import { Icon, Image } from '@/shared/ui'
import { formatNumber } from '@/shared/utils'

import { userStore } from '../../model/user.store'

import c from './UserData.module.scss'

export function UserData({ direction }: { direction: 'horizontal' | 'vertical' }) {
	const { t } = useTranslation()
	const { avatar, balance, tg_uid, tg_username } = useStore(userStore)
	const prettyBalance = formatNumber(balance, 4)

	const vertical = (
		<div className={c['user-data']} data-direction='vertical'>
			<div className={c['user-data__data']}>
				<Image w={52} src={avatar} className={c['user-data__data-avatar']} />
				<div className={c['user-data__data-name']}>{tg_username}</div>
				<div className={c['user-data__data-id']}>
					<span>{t('id')}:&nbsp;</span>
					<span className={c['id']}>{tg_uid}</span>
					<Icon name='copy' w={13} h={16} />
				</div>

				<div className={c['user-data__balance']}>
					<span>{t('balance')}</span>
					<div className={c['balance']}>
						<b>{prettyBalance}</b>
						<span>&nbsp;$</span>
					</div>
				</div>
			</div>
		</div>
	)

	const hotizontal = (
		<div className={c['user-data']} data-direction='horizontal'>
			<div className={c['user-data__data']}>
				<Image w={52} src={avatar} className={c['user-data__data-avatar']} />
				<div className={c['user-data__data-name']}>{tg_username}</div>
				<div className={c['user-data__data-id']}>
					<span>{t('id')}:&nbsp;</span>
					<span className={c['id']}>{tg_uid}</span>
				</div>
			</div>
			<div className={c['user-data__balance']}>
				<span>{t('balance')}</span>
				<div className={c['balance']}>
					<b>{prettyBalance}</b>
					<span>&nbsp;$</span>
				</div>
			</div>
		</div>
	)

	return direction === 'vertical' ? vertical : hotizontal
}
