import { useTranslation } from 'react-i18next'
import { useStore } from 'zustand'

import { Icon } from '@/shared/ui'
import { formatNumber } from '@/shared/utils'

import { userStore } from '../../model/user.store'

import c from './UserStatistic.module.scss'

export function UserStatistic() {
	const { t } = useTranslation()

	const { wins, looses, games_total } = useStore(userStore)

	const tl = formatNumber(games_total)
	const w = formatNumber(wins)
	const l = formatNumber(looses)

	return (
		<div className={c['user-statistic']}>
			<div className={c['user-statistic__label']}>
				<Icon name='star' w={15} />
				<span>{t('user-profile_statistic-label')}</span>
				<Icon name='star' w={15} />
			</div>
			<div className={c['user-statistic__total']}>
				<span>{tl}</span>
				<span>{t('user-profile_games-total')}</span>
			</div>
			<div className={c['user-statistic__wins']}>
				<span>{w}</span>
				<span>{t('user-profile_wins')}</span>
			</div>
			<div className={c['user-statistic__losses']}>
				<span>{l}</span>
				<span>{t('user-profile_losses')}</span>
			</div>
		</div>
	)
}
