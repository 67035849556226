import { useStore } from 'zustand'

import { gameStore } from '../model/game.store'

export function useGameUser() {
	const playerSeat = useStore(gameStore, s => s.playerSeat)
	const chairs = useStore(gameStore, s => s.game.chairs)

	if (playerSeat) return chairs[playerSeat]
	else return null
}
