import { createSlice } from '@/shared/utils'

// import { WebSocketManager } from '@/shared/utils'
import { gameDynamicsInit } from './game.init'
import type { GameStore } from './game.types'

export const gameStore = createSlice<GameStore>({
	// ws: new WebSocketManager(''),
	ws: null,
	playerSeat: null,
	autoRebuy: { active: false, ammount: 0 },
	premove: null,
	positions: [],

	game: gameDynamicsInit,
	animations: { players: 'clear-hands', table: 'clear-table', chips: 'to-player' },
	activePlayers: new Set()
})
