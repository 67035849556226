import { useState } from 'react'

export function useImageError() {
	const [isImageValid, setImageValid] = useState<boolean>(true)
	const onImageError = () => setImageValid(false)

	const resetImageError = () => setImageValid(true)

	return { onImageError, isImageValid, resetImageError }
}
