import cn from 'classnames'
import { useEffect, useRef } from 'react'

import { CARD_SPRITE_INIT_SIZE } from '@/app/configs/envs'

import { useGetCardSprite } from '@/entities/files'

import type { GameCardProps } from '../../model/game.types'

import c from './GameCard.module.scss'

export function GameCard({ in_combo, name, size = 's', flipped }: GameCardProps) {
	const frontSprite = useGetCardSprite(name)
	const backSprite = '/card-back.svg'

	const canvasFrontRef = useRef<HTMLCanvasElement>(null)
	const canvasBackRef = useRef<HTMLCanvasElement>(null)

	useEffect(() => {
		const frontCanvas = canvasFrontRef.current
		const backCanvas = canvasBackRef.current

		if (!frontCanvas || !backCanvas) return

		const frontContext = frontCanvas.getContext('2d')
		const backContext = backCanvas.getContext('2d')

		if (!frontContext || !backContext) return

		const front = new Image()
		front.src = frontSprite
		const back = new Image()
		back.src = backSprite

		back.onload = () => backContext.drawImage(back, 0, 0, ...CARD_SPRITE_INIT_SIZE)
		front.onload = () => frontContext.drawImage(front, 0, 0, ...CARD_SPRITE_INIT_SIZE)
	}, [frontSprite])

	return (
		<div
			className={cn(
				c['game-card'],
				in_combo && name !== 'PLUG' && c['--highlighted'],
				size && c[`--size-${size}`],
				flipped && c['--flipped']
			)}>
			<div className={c['game-card-inner']}>
				<canvas
					className={c['game-card__canvas-back']}
					ref={canvasBackRef}
					width={CARD_SPRITE_INIT_SIZE[0]}
					height={CARD_SPRITE_INIT_SIZE[1]}
				/>
				<canvas
					className={c['game-card__canvas-front']}
					ref={canvasFrontRef}
					width={CARD_SPRITE_INIT_SIZE[0]}
					height={CARD_SPRITE_INIT_SIZE[1]}
				/>
			</div>
		</div>
	)
}
