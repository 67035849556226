// Actions
import * as supportActions from './model/support.actions'

export const useSupportActions = () => ({ ...supportActions })

// Store
export { supportStore } from './model/support.store'
export * from './model/support.types'

// UI
export { default as SupportPage } from './ui/SupportPage/SupportPage'
