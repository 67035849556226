import { ComboSectionProps } from '../../model/support.types'

import c from './ComboSection.module.scss'

import { ComboSectionCard } from './ComboSectionCard'

export function ComboSection({ title, cards, description }: ComboSectionProps) {
	return (
		<section className={c['combo-section']}>
			<h1 className={c['combo-section__title']}>{title}</h1>
			<div className={c['combo-section__cards']}>
				{cards.map((c, i) => (
					<ComboSectionCard key={`combo-s-${i}`} name={c.name} in_combo={c.in_combo} />
				))}
			</div>
			<p className={c['combo-section__description']}>{description}</p>
		</section>
	)
}
