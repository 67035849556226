export const API_BASE_URL = import.meta.env.V_API_URL
export const API_WS_URL = import.meta.env.V_API_URL_WS
export const IS_DEV_ENV = import.meta.env.DEV

export const GAME_ROUND_TIMEOUT = 1000 * 5 // 5 sec

// [totalSeats, radius, offsetX, offsetY, relativeOffsetX, relativeOffsetY]
export const POSITIONS_PARAMS_PLAYER = [6, 40, -1, 2, 0, -2.5] as const
export const POSITIONS_PARAMS_SEAT = [6, 46, 6, -2, 0, -2.5] as const

export const CARD_SPRITE_INIT_SIZE = [240, 337] as const

export const POPUP_MESSAGE_DELAY = 1000 * 5 // 5 sec
