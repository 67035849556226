import { lazy } from 'react'
import { createBrowserRouter } from 'react-router-dom'

import { SupportPage } from '@/components/Support'

import { ErrorBoundary } from '@/shared/ui'

const DevPage = lazy(() => import('@/pages/Dev'))
const GamePage = lazy(() => import('@/pages/Game'))
const LoadingPage = lazy(() => import('@/pages/Loading'))
const LobbyPage = lazy(() => import('@/pages/Lobby'))
const MenuPage = lazy(() => import('@/pages/Menu'))

export const routes = createBrowserRouter([
	{
		path: '/',
		ErrorBoundary: ErrorBoundary,
		children: [
			{ path: '/', Component: LoadingPage },
			{ path: '/menu', Component: MenuPage },
			{ path: '/lobby', Component: LobbyPage },
			{ path: '/game', Component: GamePage },
			{ path: '/support', Component: SupportPage },
			{ path: '/dev', Component: DevPage }
		]
	}
])
