import cn from 'classnames'
import { useEffect, useRef } from 'react'

import { useImageError } from '@/shared/hooks'

import c from './Image.module.scss'

interface ImageProps {
	src: string | null | undefined
	w: number
	h?: number
	className?: string
	plugElement?: React.ReactNode
}

export function Image({ src, h, w, className, plugElement }: ImageProps) {
	const { isImageValid, onImageError, resetImageError } = useImageError()
	const srcRef = useRef<ImageProps['src']>(src)

	useEffect(() => {
		if (srcRef.current !== src) resetImageError()
	}, [resetImageError, src])

	return (
		<div className={cn(c.image, className)} data-src={src}>
			{isImageValid && (
				<img src={src ?? 'error-src'} width={w} height={h ?? w} onError={onImageError} />
			)}
			{!isImageValid && plugElement}
		</div>
	)
}
